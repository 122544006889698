import { initializeApp } from "firebase/app";
import {
  getFirestore,
  getDoc,
  doc,
  updateDoc,
  collection,
  setDoc,
  addDoc,
  getDocs,
  where,
  query,
  deleteDoc,
} from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyCA5kBa_l3KFAcquVsY4VwqMJFab2CJhEQ",
  authDomain: "noterio-dev.firebaseapp.com",
  projectId: "noterio-dev",
  storageBucket: "noterio-dev.appspot.com",
  messagingSenderId: "56769340777",
  appId: "1:56769340777:web:a9a847622fd3bc305e89f8",
};

initializeApp(firebaseConfig);

const db = getFirestore();
const storage = getStorage();
const auth = getAuth();

const createUpdateNote = async (noteId: any, content: any, userId: string) => {
  if (!content) {
    return;
  }
  try {
    if (noteId) {
      // Get a note
      const noteRef = doc(db, "notes", noteId);
      const docSnap = await getDoc(noteRef);

      if (docSnap.exists()) {
        // Update note
        await updateDoc(noteRef, {
          content,
          userId,
        });
      } else {
        // add note to db
        const notes = collection(db, "notes");
        await setDoc(doc(notes, noteId), {
          content,
          userId,
        });
      }
    } else {
      // add note to db
      const notes = collection(db, "notes");
      await setDoc(doc(notes, noteId), {
        content,
        userId,
      });
    }
  } catch (error) {
    console.error("Error updating document: ", error);
    throw error;
  }
};

const getNote = async (noteId: string) => {
  const noteRef = doc(db, "notes", noteId);
  const docSnap = await getDoc(noteRef);

  if (docSnap.exists()) {
    return docSnap.data();
  } else {
    return null;
  }
};

const updateNoteName = async (noteId: string, name: string) => {
  const noteRef = doc(db, "notes", noteId);
  await updateDoc(noteRef, {
    name,
  });
};

const deleteNote = async (noteId: string) => {
  const noteRef = doc(db, "notes", noteId);
  await deleteDoc(noteRef);
};

const getNotesByUser = async (userId: string) => {
  const notesRef = collection(db, "notes");
  const q = query(notesRef, where("userId", "==", userId));
  const querySnapshot = await getDocs(q);
  const notes = querySnapshot.docs.map((doc) => ({
    ...doc.data(),
    id: doc.id,
  }));
  return notes;
};

const createUserDocument = async (uid: string, data: any) => {
  const userRef = doc(db, "users", uid);
  const docSnap = await getDoc(userRef);

  if (!docSnap.exists()) {
    await setDoc(userRef, data);
  }
};

const addFeedback = async (data: any) => {
  const feedbackRef = collection(db, "feedback");
  await addDoc(feedbackRef, data);
};

export {
  createUpdateNote,
  getNote,
  storage,
  auth,
  createUserDocument,
  getNotesByUser,
  updateNoteName,
  deleteNote,
  addFeedback,
};
